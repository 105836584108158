export const FILTER_MODULE = {
  GUESTS: 'hotel_list_view',
  DELEGATE: 'delegate_list',
  HOTELS: 'hotel_master',

  FINANCE: {
    ACCOMMODATION: 'finance_list_view',
    TOUR: 'finance_list_view',
    EXHIBITORS_SUB_MODULE: 'finance_exhibitor',
  },

  VEHICLES: {
    PRIVATE: 'vehicle_details_private_view',
    SHARED: 'vehicle_details_shared_view',
    ON_DEMAND: 'vehicle_ondemand_view',
    PASSENGER: 'passenger_view',
  },

  PR_DETAILS: 'pr_details',
  SOS: {
    REQUESTED: 'sos_details',
    HISTORY: 'sos_details',
  },
  INQUIRIES: {
    GENERAL: 'general_inquiries',
    SPONSORSHIP: 'sponsorship_forms',
    EXHIBITORS: 'exhibitor_inquiries',
  },

  TOUR: 'tour_details_view',
  EXHIBITORS: 'exhibitor_view',
  QR_HISTORY: 'qr_histories',
  REPORTS: 'reports',
};

export const ROLES = {
  admin: 'admin',
  accommodation: 'accommodation',
  tour: 'tour',
  finance: 'finance',
  hotel: 'hotel',
};
