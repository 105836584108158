import { auth, endpoints } from 'src/utils/axios';

export const getNotificationList = async (params) => {
  const url = endpoints.notification.list;
  const { data } = await auth.get(url);
  return data;
};

export const readNotifications = async (params) => {
  const url = `${endpoints.notification.read}/${params}`;
  const { data } = await auth.put(url);
  return data;
};
