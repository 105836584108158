/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// @mui

// components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useEffect, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TableNoData, useTable } from 'src/components/table';

// ----------------------------------------------------------------------

const IRCDialog = styled(Dialog)(({ theme }) => ({}));
// const IRcHotelInfoBox = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   justifyContent: 'flex-end',
//   alignItems: 'center',
//   // textAlign: 'right',
//   width: '100%',
//   // marginBottom: 25,
// }));

// const IRcHotelInfoLink = styled(Link)(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   // color: theme.palette.grey[900],
//   whiteSpace: 'nowrap',
// }));

// const IRCHotelAvalibleRooms = styled(Box)(({ theme }) => ({
//   alignItems: 'center',
//   textAlign: 'center',
//   display: 'flex',
//   justifyContent: 'center',
//   '& h3': {
//     margin: 0,
//     marginRight: 10,
//   },
// }));

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: '', label: 'Phone No.', align: 'center' },
];

const HotelData = [
  {
    srNo: 1,
    name: 'John',
    date: '2023-12-02',
    location: 'Entry Get - 1',
    time: '10:00 AM',
    phone: '123457990',
  },
  {
    srNo: 2,
    date: '2023-12-02',
    name: 'devid',
    location: 'Entry Get - 2',
    time: '10:00 AM',
    phone: '123457990',
  },
  {
    srNo: 3,
    date: '2023-12-02',
    name: 'Rohit',
    location: 'Entry Get - 3',
    time: '10:00 AM',
    phone: '123457990',
  },
  {
    srNo: 4,
    date: '2023-12-02',
    name: 'John',
    location: 'Entry Get - 4',
    time: '10:00 AM',
    phone: '123457990',
  },
];

function AdditionalAttendeesDetails({ passengerDetailsModal, setPassengerDetailsModal }) {
  const table = useTable();
  const [tableData, setTableData] = useState([]);
  // const { enqueueSnackbar } = useSnackbar();

  const notFound = !tableData?.length || !tableData?.length;

  // const handleRemovePassengers = async (data) => {
  //   try {
  //     const payload = {
  //       car_master_id: passengerDetailsModal?.data?.car_master_id,
  //       registered_user_id: data.registered_user_id,
  //     };
  //     const res = await removePassenger(payload);
  //     enqueueSnackbar(res?.message || ' updated');
  //     setPassengerDetailsModal({
  //       isOpen: false,
  //       data: null,
  //       isApiCall: true,
  //     });
  //   } catch (err) {
  //     console.log('err: ', err);
  //     enqueueSnackbar(err?.message || ' updated', { variant: 'error' });
  //   }
  // };

  // const handleApiCall = () => {
  //   const value = {
  //     car_category: 'shared',
  //     car_master_id: passengerDetailsModal?.data?.car_master_id,
  //   };
  //   // getHandleApiCall(pageValue)
  //   getPassengersDetailsList(value)
  //     .then((res) => {
  //       setTableData(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    // handleApiCall();
    setTableData(HotelData);
  }, []);

  return (
    <IRCDialog
      fullWidth
      maxWidth="md"
      open={passengerDetailsModal?.isOpen}
      onClose={() =>
        setPassengerDetailsModal({
          isOpen: false,
          data: {},
        })
      }
    >
      {/* <FormProvider methods={methods} onSubmit={onSubmit}> */}
      <DialogTitle sx={{ mb: 2, pb: 0 }} textAlign="center" variant="h3">
        Additional Attendees Details
      </DialogTitle>

      <DialogContent>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />
              <TableBody>
                {tableData?.map((row) => (
                  <TableRow
                    key={row.registered_user_id}
                    sx={{
                      '& .MuiTableCell-root': {
                        textAlign: 'center',
                      },
                    }}
                  >
                    <TableCell sx={{ textTransform: 'capitalize' }}>{row.name}</TableCell>

                    <TableCell>{row.phone}</TableCell>
                  </TableRow>
                ))}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </DialogContent>
    </IRCDialog>
  );
}

AdditionalAttendeesDetails.propTypes = {
  passengerDetailsModal: PropTypes.object,
  setPassengerDetailsModal: PropTypes.func,
};

export default AdditionalAttendeesDetails;
