/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { getFilteredData } from 'src/api/common/Filters/filter.services';
import { getExhibitorsList } from 'src/api/finance/finance.services';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, useTable } from 'src/components/table';
import { paths } from 'src/routes/paths';
import { FILTER_MODULE } from 'src/utils/Constant';
import { getSRNo, hasValue } from 'src/utils/irc.utils';
import AdditionalAttendeesDetails from '../models/AdditionalAttendees';

const TABLE_HEAD = [
  { id: '', label: 'Sr. No', textAlign: 'center' },
  {
    id: 'exhibitor_name',
    label: 'Name',
  },
  { id: 'exhibitor_organization', label: 'Organization' },
  { id: 'exhibitor_email', label: 'Email' },
  { id: 'exhibitor_phone', label: 'Phone Number' },
  { id: 'exhibitor_subject', label: 'Subject' },
  { id: 'exhibitor_company', label: 'Company Category' },
  { id: 'exhibitor_message', label: 'Message' },
  // { id: 'comments', label: 'Special Comments' },
];

const defaultFilters = {
  // name: '',
  // designation: [],
  search: null,
};

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

const ExhibitorsTable = () => {
  const settings = useSettingsContext();

  const table = useTable({ defaultOrderBy: 'id' });

  // const denseHeight = table.dense ? 52 : 72;

  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0,
  });

  const [filters, setFilters] = useState(defaultFilters);

  const [passengerDetailsModal, setPassengerDetailsModal] = useState({
    isOpen: false,
    data: {},
    isApiCall: false,
  });

  // const dataFiltered = applyFilter({
  //   inputData: tableData,
  //   comparator: getComparator(table.order, table.orderBy),
  //   filters,
  // });

  const onChangePage = (_event, newPage) => {
    const nextPage = newPage;

    setPaginationData({
      ...paginationData,
      currentPage: nextPage,
    });
  };

  const notFound = !tableData?.length;

  const onFilters = useCallback(
    (name, value) => {
      // table.onResetPage();
      setPaginationData({
        currentPage: 1,
        lastPage: 1,
        total: 0,
        per_page: 50,
      });
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const onSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === 'asc';
      if (id !== '') {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
        onFilters('order_type', isAsc ? 'DESC' : 'ASC');
        onFilters('order_by', id);
      }
    },
    [order, orderBy]
  );

  const searchQuery = useCallback(
    _.debounce((val) => {
      onFilters('search', val);
    }, 1000),
    []
  );

  // const handleFilterName = useCallback(
  //   (event) => {
  //     onFilters('name', event.target.value);
  //   },
  //   [onFilters]
  // );

  // const handlePassengerDetailsClick = (data) => {
  //   setPassengerDetailsModal({
  //     isOpen: true,
  //     data,
  //   });
  // };

  // API call
  const getHandleApiCall = (pageValue) => {
    const objHasValue = hasValue(filters);
    return Object.keys(objHasValue).length === 0
      ? getExhibitorsList(pageValue)
      : getFilteredData({
          filter: { ...objHasValue },
          ...pageValue,
          module: FILTER_MODULE?.INQUIRIES?.EXHIBITORS,
          columns: 1,
        });
  };

  const handleApiCall = useCallback(() => {
    const pageValue = {
      page: paginationData?.currentPage,
    };
    getHandleApiCall(pageValue)
      // getGeneralList(pageValue)
      .then((res) => {
        const data = getSRNo(res?.data?.current_page, res?.data.per_page, res?.data?.data);
        setTableData(data);
        // setTableData(res?.data?.data);
        setPaginationData({
          currentPage: res?.data.current_page,
          lastPage: res?.data.last_page,
          total: res?.data.total,
          per_page: res?.data.per_page,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [paginationData?.currentPage, filters]);

  useEffect(() => {
    handleApiCall();
  }, [paginationData?.currentPage, filters]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Exhibitors"
        links={[{ name: 'Dashboard', href: paths.irc.root }, { name: 'Exhibitors' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card>
        <Grid
          container
          sx={{
            padding: '15px',
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={filters.name}
              onChange={(event) => searchQuery(event?.target?.value)}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar style={{ maxHeight: '60vh' }}>
            <Table stickyHeader size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align || 'center'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{ width: headCell.width, minWidth: headCell.minWidth }}
                    >
                      {onSort ? (
                        <TableSortLabel
                          hideSortIcon
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => onSort(headCell.id)}
                        >
                          {headCell.label}

                          {orderBy === headCell.id ? (
                            <Box sx={{ ...visuallyHidden }}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  },
                }}
              >
                {tableData?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell>{row?.sr_no}</TableCell>
                    <TableCell>
                      {row?.exhibitor_name}
                      {/* exhibitors_name */}
                    </TableCell>
                    <TableCell>
                      {row?.exhibitor_organization}
                      {/* contact_person */}
                      {/* <br /> {row?.contact_person_no} */}
                      {/* contact_person_number */}
                    </TableCell>
                    <TableCell>
                      {row?.exhibitor_email}
                      {/* contact_person_email */}
                    </TableCell>
                    <TableCell>
                      {row?.exhibitor_phone}
                      {/* company_description */}
                    </TableCell>
                    <TableCell>
                      {row?.exhibitor_subject}
                      {/* website_link */}
                    </TableCell>
                    <TableCell>
                      {row?.exhibitor_company}
                      {/* stall_requirement */}
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        color: (theme) =>
                          row?.passenger_name !== 'Pending' ? theme.palette.primary.main : '',
                        cursor: row?.passenger_name !== 'Pending' && 'pointer',
                      }}
                      onClick={() => {
                        if (row?.passenger_name !== 'Pending') {
                          handlePassengerDetailsClick(row);
                        }
                      }}
                    >
                      View
                    </TableCell> */}
                    <TableCell>
                      {row?.exhibitor_message}
                      {/* special_request */}
                    </TableCell>
                  </TableRow>
                ))}

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                /> */}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <Pagination
          component="div"
          color="primary"
          sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
          count={paginationData.lastPage}
          onChange={onChangePage}
          page={paginationData?.currentPage}
          variant="text"
        />
      </Card>

      {passengerDetailsModal?.isOpen && (
        <AdditionalAttendeesDetails
          passengerDetailsModal={passengerDetailsModal}
          setPassengerDetailsModal={setPassengerDetailsModal}
        />
      )}
    </Container>
  );
};
export default ExhibitorsTable;

// function applyFilter({ inputData, comparator, filters }) {
//   const { name, designation } = filters;
//   // filters

//   const stabilizedThis = inputData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   inputData = stabilizedThis.map((el) => el[0]);

//   if (name) {
//     inputData = inputData.filter(
//       (product) => product?.name?.toLowerCase().indexOf(name?.toLowerCase()) !== -1
//     );
//   }

//   if (designation?.length) {
//     inputData = inputData.filter((user) => designation.includes(user.designation));
//   }

//   return inputData;
// }
// Search , Short Filter Function End
