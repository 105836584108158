import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { fToNow } from 'src/utils/format-time';

export default function NotificationItem({ notification, drawer, setReadDataState }) {
  const router = useRouter();
  const Title = JSON.parse(notification?.data)?.message;

  const renderText = (
    <ListItemText
      disableTypography
      gettype={notification?.type}
      getid={notification?.id}
      primary={Title}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {fToNow(notification.created_at)}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = notification.read_at === null && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );
  const onClickMassageRoute = (props) => {
    drawer.onFalse();
    // eslint-disable-next-line react/prop-types
    switch (props.gettype) {
      case 'sos':
        return router.push(paths.irc.sos);
      case 'Ondemandcar':
        return router.push(paths.irc?.vehicles?.on_demand);
      case 'generalinquiry':
        return router.push(paths.irc?.inquires?.general);
      case 'accommodation':
        return router.push(paths.irc?.finance?.accommodation_fees);
      case 'tour':
        return router.push(paths.irc?.finance?.tour_fees);
      default:
        return router.push(paths.irc.root);
    }

    // // eslint-disable-next-line react/prop-types
    // if (props.gettype === 'sos') {
    //   router.push(paths.irc.sos);
    //   drawer.onFalse();
    // }
    // // eslint-disable-next-line react/prop-types
    // else if (props.gettype === 'On demand car') {
    //   router.push(paths.irc?.vehicles?.on_demand);
    //   drawer.onFalse();
    // }
  };

  return (
    <ListItemButton
      // disableRipple
      onClick={() => {
        onClickMassageRoute(renderText?.props);
        setReadDataState(renderText?.props?.getid);
      }}
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}
      <Stack sx={{ flexGrow: 1 }}>{renderText}</Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  drawer: PropTypes.object,
  setReadDataState: PropTypes.func,
};

// function reader(data) {
//   return (
//     <Box
//       dangerouslySetInnerHTML={{ __html: data }}
//       sx={{
//         mb: 0.5,
//         '& p': { typography: 'body2', m: 0 },
//         '& a': { color: 'inherit', textDecoration: 'none' },
//         '& strong': { typography: 'subtitle2' },
//       }}
//     />
//   );
// }
