import { Helmet } from 'react-helmet-async';
import ExhibitorsTable from './ExhibitorsTable/ExhibitorsTable';

const Exhibitors = () => (
  <>
    <Helmet>
      <title> Exhibitors : IRC Admin</title>
    </Helmet>
    <ExhibitorsTable />
  </>
);

export default Exhibitors;
