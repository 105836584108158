import { auth, endpoints } from 'src/utils/axios';

export const getAccommodationDetails = async (params) => {
  const url = endpoints.finance?.accommodationDetails;
  const { data } = await auth.get(url, { params });
  return data;
};

export const getExhibitorsList = async (params) => {
  const url = endpoints.finance?.exhibitors;
  const { data } = await auth.get(url, { params });
  return data;
};

export const getFinanceTotal = async () => {
  const url = endpoints.finance?.totalCount;
  const { data } = await auth.get(url);
  return data;
};
