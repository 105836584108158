import { useCallback, useEffect, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
//
import { useSearchParams } from 'src/routes/hooks';
import { Button } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import {
  getDelegateDetails,
  getDelegateQRHistory,
} from 'src/api/DelegateList/delegateList.services';
import AccommodationDetails from './AccommodationDetails';
import DelegateGeneralDetails from './DelegateGeneralDetails';
import QRHistoryDeleGate from '../Models/QRHistoryDeleGate';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'user',
    label: 'User',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },

  {
    value: 'accommodation',
    label: 'Accommodation',
    icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
  },
];

// ----------------------------------------------------------------------

export default function DelegateDetails() {
  const settings = useSettingsContext();

  const searchparam = useSearchParams();

  // const id = searchparam.get('id');
  const userId = searchparam.get('id');

  const [currentTab, setCurrentTab] = useState('user');
  const [delegateDetails, setDelegateDetails] = useState([]);

  const [QRHisToryModal, setQRHisToryModal] = useState({
    isOpen: false,
    data: {},
  });

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleDelegateDetailsApi = async () => {
    try {
      const res = await getDelegateDetails(userId);
      setDelegateDetails(res?.data || {});
    } catch (err) {
      console.log('err: ', err);
    }
  };

  useEffect(() => {
    handleDelegateDetailsApi();
    if (userId) {
      getDelegateQRHistory(userId)
        .then((res) => setQRHisToryModal({ ...QRHisToryModal, data: res }))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Delegate List : IRC Admin </title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Delegate Details"
          links={[
            { name: 'Dashboard', href: paths.irc.root },
            { name: 'Delegates', href: paths.irc.delegates.list },
            { name: 'Delegate Details' },
          ]}
          action={
            <>
              {/* <Button disabled variant="contained" startIcon={<Iconify icon="mingcute:add-line" />}>
                Generate ID
              </Button> */}

              <Button
                variant="contained"
                sx={{ ml: 1 }}
                startIcon={<Iconify icon="heroicons-solid:qrcode" />}
                onClick={() =>
                  setQRHisToryModal({
                    ...QRHisToryModal,
                    isOpen: true,
                  })
                }
              >
                QR History
              </Button>
            </>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        {currentTab === 'user' && <DelegateGeneralDetails delegateDetails={delegateDetails} />}

        {currentTab === 'accommodation' && (
          <AccommodationDetails delegateDetails={delegateDetails} />
        )}
      </Container>
      <QRHistoryDeleGate QRHisToryModal={QRHisToryModal} setQRHisToryModal={setQRHisToryModal} />
    </>
  );
}
