import { toastMessage } from 'src/utils/toastmessages';

const getErrorMessage = (err) => {
  if (!err.response) {
    const message = err?.message || toastMessage?.error;
    return message;
  }
  if (err.response?.status === 500) {
    return toastMessage?.error;
  }
  if (err.response.status > 400) {
    const message = err.message || err.response.data.message || toastMessage?.error;
    return message;
  }
  return toastMessage?.error;
};

export default getErrorMessage;
