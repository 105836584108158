/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
import * as Ably from 'ably';

const hasValue = (obj) => {
  let filtersKeysHasValues = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== '' &&
      obj[key] !== false &&
      obj[key]?.length !== 0
    ) {
      filtersKeysHasValues = { ...filtersKeysHasValues, [key]: obj[key] };
    }
  }
  return filtersKeysHasValues;
};

export const client = new Ably.Realtime({
  key: process.env.REACT_APP_ABLY_KEY,
});

const getSRNo = (currPage, limit, data) =>
  data.map((row, index) => ({ ...row, sr_no: (currPage - 1) * limit + 1 + index }));

const objectsEqualFilterData = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export { hasValue, getSRNo, objectsEqualFilterData };
