import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import DelegateDetailshello from 'src/pages/irc/DelegateList/DelegateDetails/DelegateDetails';
import Exhibitors from 'src/pages/irc/Inquires/Exhibitors/Exhibitors';
import AccommodationReports from 'src/pages/irc/Reports/Accommodation';
import Tour from 'src/pages/irc/Reports/Tour';
import { ROLES } from 'src/utils/Constant';

const IrcDashboard = lazy(() => import('src/pages/irc/dashboard/IrcDashboard'));
const PrOfficersDetails = lazy(() => import('src/pages/irc/PrOfficersDetails/PrOfficersDetails'));
const DelegateList = lazy(() => import('src/pages/irc/DelegateList/DelegateList'));
// const ListOfVehicle = lazy(() => import('src/pages/irc/ListOfVehicle/ListOfVehicle'));
const TourDetails = lazy(() => import('src/pages/irc/Tour/TourDetails'));
const HotelList = lazy(() => import('src/pages/irc/HotelList/HotelList'));
const ExhibitorsList = lazy(() => import('src/pages/irc/ExhibitorsList/ExhibitorsList'));
const QRHistory = lazy(() => import('src/pages/irc/QRHistory/QRHistory'));
const SOS = lazy(() => import('src/pages/irc/SOS/SOS'));
const GolfList = lazy(() => import('src/pages/irc/GolfCart/GolfCartList'));
const HotelDetails = lazy(() => import('src/pages/irc/HotelList/HotelDetails/HotelDetails'));
const Guest = lazy(() => import('src/pages/irc/GuestUsers/Guest'));
const GeneralInquires = lazy(() =>
  import('src/pages/irc/Inquires/GeneralInquires/GeneralInquires')
);
const Sponsorshipinquiries = lazy(() =>
  import('src/pages/irc/Inquires/SponsorshipInquiries/Sponsorshipinquiries')
);
const Passengers = lazy(() => import('src/pages/irc/ListOfVehicle/Riders/Riders'));
const Vehicles = lazy(() => import('src/pages/irc/ListOfVehicle/Vehicals/Vehicals'));
const OnDemand = lazy(() => import('src/pages/irc/ListOfVehicle/OnDemand/OnDemand'));
const FinanceAccommodationFees = lazy(() =>
  import('src/pages/irc/FinanceList/AccommodationFees/AccommodationFees')
);
const FinanceTour = lazy(() => import('src/pages/irc/FinanceList/TourFees/TourFees'));
const FinanceExhibitors = lazy(() => import('src/pages/irc/FinanceList/ExhibitorsFees/Exhibitors'));

const LiveStream = lazy(() => import('src/pages/irc/LiveStream/LiveStream'));
const AccommodationDetails = lazy(() =>
  import('src/pages/irc/FinanceList/AccommodationFees/AccommodationDetails/AccommodationDetails')
);

// const ReportsTabs = lazy(() => import('src/pages/irc/Reports/ReportsTabs'));

export const ircRoutes = [
  {
    path: 'irc',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <RoleBasedGuard
            hasContent
            roles={[ROLES.admin, ROLES.accommodation, ROLES?.finance, ROLES.hotel, ROLES?.tour]}
          >
            <IrcDashboard />
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'delegates',
        element: (
          <RoleBasedGuard
            hasContent
            roles={[ROLES.admin, ROLES.accommodation, ROLES?.finance, ROLES.hotel, ROLES?.tour]}
          >
            <DelegateList />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'delegates/details',
        element: (
          <RoleBasedGuard
            hasContent
            roles={[ROLES.admin, ROLES.accommodation, ROLES?.finance, ROLES.hotel, ROLES?.tour]}
          >
            <DelegateDetailshello />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'tour-details',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.tour]}>
            <TourDetails />
          </RoleBasedGuard>
        ),
      },
      // { path: 'accommodation/hotel', element: <HotelList /> },
      {
        path: 'accommodation',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.accommodation]}>
                <HotelList />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'hotel',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.accommodation]}>
                <HotelList />{' '}
              </RoleBasedGuard>
            ),
          },
          {
            path: 'hotel/details',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.accommodation]}>
                <HotelDetails />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'guest-users',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.accommodation]}>
                <Guest />
              </RoleBasedGuard>
            ),
          },
          // { path: ':id', element: <ProductDetailsPage /> },
        ],
      },
      {
        path: 'vehicles',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <Passengers />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'passengers',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <Passengers />{' '}
              </RoleBasedGuard>
            ),
          },
          {
            path: 'details',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <Vehicles />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'on-demand',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <OnDemand />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'pr',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin]}>
            <PrOfficersDetails />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'exhibitors',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin]}>
            <ExhibitorsList />
          </RoleBasedGuard>
        ),
      },
      // { path: 'finance-list', element: <FinanceListMain /> },
      {
        path: 'finance',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES.accommodation]}>
                <FinanceAccommodationFees />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'accommodation-fees',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.finance, ROLES.accommodation]}>
                <FinanceAccommodationFees />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'tour-fees',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.finance, ROLES?.tour]}>
                <FinanceTour />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'exhibitor-fees',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.finance]}>
                <FinanceExhibitors />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'finance/accommodation/details',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.finance, ROLES.accommodation]}>
            <AccommodationDetails />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'qr-history',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin]}>
            <QRHistory />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'sos',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin]}>
            <SOS />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'golf-cart',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin]}>
            <GolfList />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'inquires',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <Sponsorshipinquiries />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'sponsorship',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <Sponsorshipinquiries />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'general',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <GeneralInquires />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'exhibitor',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin]}>
                <Exhibitors />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      // { path: 'general-inquires', element: <GeneralInquires /> },
      {
        path: 'live-streaming',
        element: (
          <RoleBasedGuard hasContent roles={[ROLES.admin]}>
            <LiveStream />
          </RoleBasedGuard>
        ),
      },
      // {
      //   path: 'reports',
      //   element: (
      //     <RoleBasedGuard hasContent roles={[ROLES.admin]}>
      //       <ReportsTabs />{' '}
      //     </RoleBasedGuard>
      //   ),
      // },

      {
        path: 'reports',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES.accommodation]}>
                <AccommodationReports />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'accommodation',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES.accommodation]}>
                <AccommodationReports />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'tour',
            element: (
              <RoleBasedGuard hasContent roles={[ROLES.admin, ROLES?.tour]}>
                <Tour />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      // { path: 'accommodation/hotel/details', element: <HotelDetails /> },
    ],
  },
];
