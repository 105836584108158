import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import MainLayout from 'src/layouts/main';
// config
//
import { PATH_AFTER_LOGIN } from 'src/config-global';
// import { mainRoutes, HomePage } from './main';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
// import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { ircRoutes } from './irc-routes';

// ----------------------------------------------------------------------
// const isMenuItemExists = JSON.parse(localStorage.getItem('menuItems'));

// if (isMenuItemExists)
//   (() => {
//     const menuItems = JSON.parse(localStorage.getItem('menuItems'));

//     const isNotExistRoute = menuItems?.items.filter((element) =>
//       window.location.pathname.includes(element.path)
//     );

//     if (
//       !isNotExistRoute ||
//       (isNotExistRoute?.length === 1 && isNotExistRoute[0].path !== window.location.pathname)
//     ) {
//       window.location.href = '/404';
//     }
//   })();

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <HomePage />
    //     </MainLayout>
    //   ),
    // },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Irc
    ...ircRoutes,

    // Dashboard routes
    // ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
