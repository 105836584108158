export const toastMessage = {
  error: 'Something Went Wrong',
  hotel: {
    reservation: 'Reservation Successfully.',
  },
  sos: {
    update_sos: 'SOS Updated Successfully.',
  },
  liveStream: 'Url Updated Successfully.',
};
